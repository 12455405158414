import { useEffect, useRef, useState } from 'react'
import './style.scss'

const DropDownList = ({ list, selectedValue, changeValue }) => {
  const [open, setOpen] = useState(false)
  const selectedRef = useRef(null)

  useEffect(() => {
    const click = e => e.target !== selectedRef.current && setOpen(false)

    window.addEventListener('click', click)
    return () => window.removeEventListener('click', click)
  }, [])

  const elementClicked = element => {
    changeValue(element)
    setOpen(false)
  }

  return (
    <div className='dropDown'
      onClick={() => setOpen(!open)}>

      <div
        ref={selectedRef}
        className='dropDown__selected'>{selectedValue}</div>
        
      {open && <div className='dropDown__list'>
        {list.map(e => <div
          key={e}
          className={`dropDown__item ${selectedValue === e ? '--selected' : ''}`}
          onClick={() => elementClicked(e)}>
          <p>{e}</p>
        </div>)}
      </div>}
    </div>
  )
}

export default DropDownList